<template>
    <div>
        <y-form-uploader
            v-model="model"
            :name="album.name"
            class="mb0"
            :label="album.label"
            :max-file-size="album.max_volume_files"
            :max-files="album.max_number_files"
            :payload="album.payload"
            :files="album.files"
            :accepted-files="album.allowed_extensions"
            :options="album.options"
        />
    </div>
</template>

<script>

    export default {

        name: 'SimpleAlbum',

        components: {
            YFormUploader: () => import('@deps/form/elements/Uploader'),
        },

        props: {
            album: {
                type    : Object,
                required: true,
            },
        },

        /**
         * @inheritDoc
         */
        data() {
            return {
                model: this.value || [],
            };
        },

        watch: {
            /**
             * Watch value set new model
             */
            value: {
                // eslint-disable-next-line require-jsdoc
                handler(val) {
                    this.$set(this, 'model', val);
                },
                deep: true,
            },

            /**
             * Watch value set new model
             */
            model: {
                // eslint-disable-next-line require-jsdoc
                handler() {
                    this.$emit('input', this.model);
                },
                deep: true,
            },
        },

    };
</script>
